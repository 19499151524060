import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { trainwellColor } from "../theme/themePrimitives";

/**
 * trainwell Outlined Button according to the specs. Should be reused every time
 * a outlined button is needed.
 */
export const OutlinedButton = styled(Button)(({ theme }) => ({
  border: `1px solid ${trainwellColor.primary2}`,
  backgroundColor: trainwellColor.neutral1,
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  padding: theme.spacing(2, 3),
  display: "flex",
  justifyContent: "space-between",
  "&.Mui-selected": {
    backgroundColor: trainwellColor.primary1,
    border: `1px solid ${trainwellColor.primary4}`, // Set the border color and width
  },
}));
