import { createFileRoute } from "@tanstack/react-router";
import { useUserState } from "src/components/UserState";
import SingleSelectionPageComponent from "src/features/SingleSelectionPageComponent";

export const Route = createFileRoute("/_layout/trainerGender")({
  component: RouteComponent,
});

function RouteComponent() {
  const setSelections = useUserState((state) => state.setSelections);

  return (
    <SingleSelectionPageComponent
      titleText="Do you have a gender preference for your trainer?"
      bodyText="Choose what makes you most comfortable."
      items={[
        { id: "male", label: "Male" },
        { id: "female", label: "Female" },
        { id: "no_preference", label: "No Preference" },
      ]}
      onSelectionChange={(selectedItem) => {
        console.log(selectedItem);

        if (selectedItem !== null) {
          setSelections("/trainerGender", [selectedItem]);
        } else {
          setSelections("/trainerGender", undefined);
        }
      }}
      selectionText="You can change your trainer at any time."
    />
  );
}
