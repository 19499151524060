import { Box, Container, useTheme } from "@mui/material";
import type { FreemiumPlanResponseDetailType } from "@trainwell/features/freemium-plans";
import { CoachAvatar } from "src/components/CoachAvatar";
import { CreatedByTitle } from "src/components/training-plans/CreatedByTitle";
import { PlanTitle } from "src/components/training-plans/PlanTitle";
import { trainwellColor } from "src/theme/themePrimitives";

interface TrainerCardSmallProps {
  plan: FreemiumPlanResponseDetailType;
  selected?: boolean;
}

const TrainerPlanCardSmall = ({ plan }: TrainerCardSmallProps) => {
  const theme = useTheme();

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        position: "relative",
        width: "100%",
        maxWidth: "300px",
        borderRadius: theme.spacing(2),
        border: `2px solid ${trainwellColor.primary1}`,
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        height: "100%",
      }}
    >
      <Box
        component="img"
        src={plan.summary.gradient_image_url}
        alt="Background"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "80px",
          objectFit: "cover",
          borderTopLeftRadius: theme.spacing(2),
          borderTopRightRadius: theme.spacing(2),
        }}
      />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CoachAvatar
          src={plan.trainer.headshot_url}
          alt="Creator Avatar"
          sx={{
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}
        />
        <CreatedByTitle>{`Created by ${plan.trainer.first_name}`}</CreatedByTitle>
        <PlanTitle>{plan.summary.title}</PlanTitle>
      </Box>
    </Container>
  );
};

export default TrainerPlanCardSmall;
