import { Box } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";
import { useUserState } from "src/components/UserState";
import SingleSelectionPageComponent from "src/features/SingleSelectionPageComponent";

export const Route = createFileRoute("/_layout/duration")({
  component: RouteComponent,
});

function RouteComponent() {
  const setSelections = useUserState((state) => state.setSelections);

  return (
    <SingleSelectionPageComponent
      titleText="How long do you like to workout?"
      bodyText="This will provide a starting point for your plan."
      items={[
        {
          label: (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              30 minutes
            </Box>
          ),
          id: "30",
        },
        {
          label: (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              60 minutes
            </Box>
          ),
          id: "60",
        },
      ]}
      selectionText="These times are flexible - adjust them anytime later to fit your schedule."
      onSelectionChange={(selectedItem) => {
        console.log(selectedItem);
        if (selectedItem !== null) {
          setSelections("/duration", [selectedItem]);
        } else {
          setSelections("/duration", undefined);
        }
      }}
    />
  );
}
