import { Box, useTheme } from "@mui/material";
import type {
  FreemiumPhaseDetailsResponseType,
  FreemiumPlanResponseDetailType,
} from "@trainwell/features/freemium-plans";
import {
  PrimaryBadge,
  SecondaryBadge,
  SuccessBadge,
} from "src/components/FreeboardingBadge";
import { FreeboardingSubtitle } from "src/components/FreeboardingSubtitle";
import { FreeboardingTitle } from "src/components/FreeboardingTitle";
import { HeaderDescription } from "src/components/plan-details/HeaderDescription";
import { HeaderTitle } from "src/components/plan-details/HeaderTitle";
import { InfoTableItem } from "src/components/plan-details/InfoTableItem";
import { InfoTableLabel } from "src/components/plan-details/InfoTableLabel";
import { InfoTableValue } from "src/components/plan-details/InfoTableValue";
import { SectionDescription } from "src/components/plan-details/SectionDescription";
import ReviewCard from "./ReviewCard";
import WorkoutCardItem from "./WorkoutCardItem";

interface TrainingPlanDetailsPageProps {
  plan: FreemiumPlanResponseDetailType;
  phaseDetails?: FreemiumPhaseDetailsResponseType;
}

const TrainingPlanDetailsPageComponent = ({
  plan,
  phaseDetails,
}: TrainingPlanDetailsPageProps) => {
  const theme = useTheme();

  return (
    <Box display="flex" flexDirection="column" width="100%" maxWidth="600px">
      <Box
        sx={{
          display: "flex",
          width: "100%",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderTopLeftRadius: theme.spacing(1),
          borderTopRightRadius: theme.spacing(1),
          borderBottomLeftRadius: theme.spacing(1),
          borderBottomRightRadius: theme.spacing(1),
          overflow: "hidden",
          backgroundImage: `url(${plan.summary.gradient_image_url})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: theme.spacing(2),
        }}
      >
        <HeaderTitle>{plan.summary.title}</HeaderTitle>
        <HeaderDescription>{plan.summary.subtitle}</HeaderDescription>
      </Box>
      <Box>
        <FreeboardingTitle sx={{ textAlign: "start" }}>
          Why this Plan?
        </FreeboardingTitle>
        <SectionDescription>
          {plan.summary.description_short}
        </SectionDescription>
      </Box>

      <Box
        style={{
          marginTop: theme.spacing(2),
          marginBottom: theme.spacing(2),
        }}
      >
        <InfoTableItem>
          <InfoTableLabel>Target</InfoTableLabel>
          <InfoTableValue>
            {plan.plan.goals
              // TODO(akrulec): This should probably be a general function somewhere.
              .map((m) =>
                m
                  .split("_")
                  .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                  .join(" "),
              )
              .join(", ")}
          </InfoTableValue>
        </InfoTableItem>
        <InfoTableItem>
          <InfoTableLabel>Frequency</InfoTableLabel>
          <InfoTableValue>{plan.plan.target_frequency} per week</InfoTableValue>
        </InfoTableItem>
        <InfoTableItem>
          <InfoTableLabel>Duration</InfoTableLabel>
          <InfoTableValue>{plan.plan.target_duration} min each</InfoTableValue>
        </InfoTableItem>
      </Box>

      <Box
        sx={{
          width: "100%",
          height: "auto",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          backgroundImage: `url(${plan.trainer.headshot_with_fade_url})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          paddingTop: "56.25%", // 16:9 aspect ratio for now.
          position: "relative",
          marginBottom: theme.spacing(2),
        }}
      ></Box>
      <Box>
        <SectionDescription>{plan.trainer.short_about}</SectionDescription>
      </Box>

      <Box>
        <FreeboardingSubtitle>Specialties</FreeboardingSubtitle>
        {plan.trainer.specialties.map((specialty, index) => (
          <SuccessBadge
            key={index}
            label={specialty}
            style={{
              marginRight: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          />
        ))}
      </Box>
      <Box>
        <FreeboardingSubtitle>Interests</FreeboardingSubtitle>
        {plan.trainer.loves.map((specialty, index) => (
          <SecondaryBadge
            key={index}
            label={specialty}
            style={{
              marginRight: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          />
        ))}
      </Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <FreeboardingSubtitle>Client Reviews</FreeboardingSubtitle>
          {/* TODO(akrulec): Figure out if this number comes from anywhere. */}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: theme.spacing(2),
          }}
        >
          {plan.reviews.map((review, index) => {
            const startYear = new Date(review.client_start_year).getFullYear();
            return (
              <ReviewCard
                key={index}
                name={review.client_name}
                // TODO: Add User's headshot URL once it's available.
                imageSrc=""
                subtitle={`User since ${startYear}`}
                review={review.content}
              />
            );
          })}
        </Box>
      </Box>
      <Box>
        <FreeboardingTitle sx={{ textAlign: "start" }}>
          Plan Preview
        </FreeboardingTitle>
        {plan.plan.tags.map((specialty, index) => (
          <PrimaryBadge
            key={index}
            label={specialty}
            style={{
              marginRight: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          />
        ))}
        <InfoTableValue
          sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(2) }}
        >
          {plan.plan.phase_count} Phases · {plan.plan.total_weeks} Weeks
        </InfoTableValue>
        <SectionDescription>
          This trainer-designed program adapts to your level and schedule, with
          clear guidance and proven workout methods to help you reach your
          goals.
        </SectionDescription>
      </Box>
      <Box>
        <FreeboardingSubtitle>Required Equipment</FreeboardingSubtitle>
        {plan.plan.required_equipment.map((equipment, index) => (
          <SuccessBadge
            key={index}
            label={equipment}
            style={{
              marginRight: theme.spacing(1),
              marginBottom: theme.spacing(1),
            }}
          />
        ))}
        <SectionDescription>
          Most exercises can be modified or swapped to match your setup.
        </SectionDescription>
      </Box>
      <Box>
        <FreeboardingSubtitle>Workout Routine</FreeboardingSubtitle>
        <SectionDescription
          style={{
            marginBottom: theme.spacing(2),
          }}
        >
          {plan.plan.workout_routine_description}
        </SectionDescription>
        {phaseDetails?.workout_details.map((workout, index) => (
          // TODO(akrulec): Add workout image once it's available.
          <WorkoutCardItem
            key={index}
            title={workout.name}
            subtitle={
              workout.exercise_count + " exercises • " + workout.duration
            }
            thumbnail_url=""
          />
        ))}
      </Box>
      <Box>
        <FreeboardingSubtitle>Common Movements</FreeboardingSubtitle>
        <SectionDescription sx={{ marginBottom: theme.spacing(2) }}>
          {plan.plan.common_movements_description}
        </SectionDescription>
        {phaseDetails?.key_exercises.map((exercise, index) => (
          <WorkoutCardItem
            key={index}
            title={exercise.name}
            subtitle=""
            // TODO(akrulec): Add subtitle once it's available.
            // subtitle={exercise.muscle
            //   .map((m) =>
            //     m
            //       .split("_")
            //       .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            //       .join(" "),
            //   )
            //   .join(" & ")}
            thumbnail_url={exercise.thumbnail_url}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TrainingPlanDetailsPageComponent;
