import { styled } from "@mui/material/styles";
import { trainwellColor } from "../theme/themePrimitives";

/** Title used for most of the freeboarding screens. */
export const FreeboardingTitle = styled("h5")(({ theme }) => ({
  color: trainwellColor.neutral8,
  ...theme.typography.h5,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
  textAlign: "center",
}));
