import { Box } from "@mui/material";
import { WorkoutCard } from "src/components/plan-details/WorkoutCard";
import { WorkoutCardImage } from "src/components/plan-details/WorkoutCardImage";
import { WorkoutCardSubtitle } from "src/components/plan-details/WorkoutCardSubtitle";
import { WorkoutCardTitle } from "src/components/plan-details/WorkoutCardTitle";
import { theme } from "src/theme/theme";

interface WorkoutCardItemProps {
  title: string;
  subtitle: string;
  thumbnail_url?: string | undefined;
}

const WorkoutCardItem = ({
  title,
  subtitle,
  thumbnail_url,
}: WorkoutCardItemProps) => {
  return (
    <WorkoutCard>
      {thumbnail_url ? (
        <img
          src={thumbnail_url}
          style={{
            width: 56,
            height: 56,
            marginRight: theme.spacing(2),
            borderRadius: theme.spacing(1),
          }}
          alt="Workout Thumbnail"
        />
      ) : (
        <WorkoutCardImage />
      )}
      <Box>
        <WorkoutCardTitle>{title}</WorkoutCardTitle>
        <WorkoutCardSubtitle>{subtitle}</WorkoutCardSubtitle>
      </Box>
    </WorkoutCard>
  );
};

export default WorkoutCardItem;
