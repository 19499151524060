import { createFileRoute } from "@tanstack/react-router";
import { useUserState } from "src/components/UserState";
import SingleSelectionPageComponent from "src/features/SingleSelectionPageComponent";

export const Route = createFileRoute("/_layout/numDays")({
  component: RouteComponent,
});

function RouteComponent() {
  const setSelections = useUserState((state) => state.setSelections);

  return (
    <SingleSelectionPageComponent
      titleText="How many days a week do you want to work out?"
      bodyText="We recommend beginners start with fewer days and work up to more."
      items={[
        { label: "Beginner (3 days)", id: "3" },
        {
          label: "Intermediate + Advanced (5 days)",
          id: "5",
        },
      ]}
      onSelectionChange={(selectedItem) => {
        console.log(selectedItem);
        if (selectedItem !== null) {
          setSelections("/numDays", [selectedItem]);
        } else {
          setSelections("/numDays", undefined);
        }
      }}
    />
  );
}
