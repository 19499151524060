import { Box, Typography } from "@mui/material";
import { createFileRoute } from "@tanstack/react-router";
import { useFreemiumPlanMatches } from "@trainwell/features/freemium-plans";
import { useEffect, useState } from "react";
import { useUserState } from "src/components/UserState";
import LottiePageComponent from "src/features/LottiePageComponent";
import TrainingPlanPageComponent from "src/features/TrainingPlanPageComponent";
import TrainWithContainer from "src/features/TrainWithContainer";

export const Route = createFileRoute("/_layout/trainingPlans")({
  component: RouteComponent,
});

function RouteComponent() {
  const userId = useUserState((state) => state.userId);
  const [delayedLoading, setDelayedLoading] = useState(true);
  const minimumLoadingTime = 4000; // 4 seconds

  const {
    data: plans,
    isError,
    isPending,
  } = useFreemiumPlanMatches({
    filter: {
      userId: userId || "",
      detailed: true,
    },
  });

  useEffect(() => {
    // If the query is not loading anymore, start a timer
    if (!isPending && delayedLoading) {
      const timer = setTimeout(() => {
        setDelayedLoading(false);
      }, minimumLoadingTime);

      // Clean up timer on unmount
      return () => {
        clearTimeout(timer);
      };
    }

    // If the query starts loading again, reset our delayed loading state
    if (isPending && !delayedLoading) {
      setDelayedLoading(true);
    }
  }, [isPending, delayedLoading]);

  console.log("plans", plans);

  if (isError) {
    return <Typography>oh noes, error happened</Typography>;
  }

  // Use the combined loading state (original + delayed)
  if (isPending || delayedLoading) {
    return (
      <LottiePageComponent
        chipText="All of our plans are 100% free, forever!"
        titleText="Finding plans that match your style and goals"
        bodyText="We're curating training plans from our trainer-designed collection that'll feel like they were made just for you!"
        customComponent={
          <TrainWithContainer
            text="loved by 45,000+ members"
            images={[
              "../assets/stock-image-1.png",
              "../assets/stock-image-2.png",
              "../assets/stock-image-3.png",
            ]}
          />
        }
      />
    );
  }

  return (
    <Box>
      <TrainingPlanPageComponent
        titleText="We've found some great plans for you!"
        bodyText="Once you select a plan, we'll customize it to fit your exact ability and goals."
        chipText="All of our plans are 100% free, forever!"
        trainingPlans={plans}
      />
    </Box>
  );
}
