import { Box } from "@mui/material";
import { useEffect, type ReactNode } from "react";
import { useButtonStore } from "src/components/ButtonState";
import { SuccessBadge } from "../components/FreeboardingBadge";
import { FreeboardingParagraph } from "../components/FreeboardingParagraph";
import { FreeboardingTitle } from "../components/FreeboardingTitle";

/**
 * This component displays simple text pages with a chip, title, and paragraph. At the bottom,
 * its has an optional custom component that can be anything.
 */

interface TextPageProps {
  chipText?: string;
  titleText: string;
  bodyText: string;
  customComponent?: ReactNode;
}

const TextPageComponent = ({
  chipText,
  titleText,
  bodyText,
  customComponent,
}: TextPageProps) => {
  const setButtonEnabled = useButtonStore((state) => state.setButtonEnabled);

  useEffect(() => {
    setButtonEnabled(true);
  }, [setButtonEnabled]);

  return (
    <Box
      display="flex"
      maxWidth="420px"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {chipText && <SuccessBadge label={chipText} />}
      <FreeboardingTitle>{titleText}</FreeboardingTitle>
      <FreeboardingParagraph>{bodyText}</FreeboardingParagraph>
      <Box marginTop={2}>{customComponent}</Box>
    </Box>
  );
};

export default TextPageComponent;
