import { styled } from "@mui/material/styles";
import { trainwellColor } from "../theme/themePrimitives";

/**
 * Subtitle, or body used for most of the text that is in a paragraph on
 * freeboarding screens.
 */
export const FreeboardingParagraph = styled("p")(({ theme }) => ({
  color: trainwellColor.neutral6,
  ...theme.typography.subtitle1,
  textAlign: "center",
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(0),
}));
