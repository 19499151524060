import { styled } from "@mui/material/styles";
import { trainwellColor } from "src/theme/themePrimitives";

export const HeaderTitle = styled("h6")(({ theme }) => ({
  color: trainwellColor.neutral8,
  ...theme.typography.h6,
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(0),
  textAlign: "center",
  textTransform: "uppercase",
}));
