import { Box } from "@mui/material";
import { ReviewCardAvatar } from "src/components/plan-details/ReviewCardAvatar";
import { ReviewCardContainer } from "src/components/plan-details/ReviewCardContainer";
import { ReviewCardDescription } from "src/components/plan-details/ReviewCardDescription";
import { ReviewCardSubtitle } from "src/components/plan-details/ReviewCardSubtitle";
import { ReviewCardTitle } from "src/components/plan-details/ReviewCardTitle";
import { theme } from "src/theme/theme";

interface ReviewCardProps {
  name: string;
  imageSrc: string;
  subtitle: string;
  review: string;
}

const ReviewCard = ({ name, imageSrc, subtitle, review }: ReviewCardProps) => {
  return (
    <ReviewCardContainer>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <ReviewCardAvatar alt={name} src={imageSrc} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ReviewCardTitle>{name}</ReviewCardTitle>
          <ReviewCardSubtitle>{subtitle}</ReviewCardSubtitle>
        </Box>
      </Box>
      <ReviewCardDescription sx={{ marginTop: theme.spacing(2) }}>
        {review}
      </ReviewCardDescription>
    </ReviewCardContainer>
  );
};

export default ReviewCard;
