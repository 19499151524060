import { Box, Grid, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useButtonStore } from "../components/ButtonState";
import { FreeboardingParagraph } from "../components/FreeboardingParagraph";
import { FreeboardingTitle } from "../components/FreeboardingTitle";
import { OutlinedButton } from "../components/OutlinedButton";
import type { SelectionButtonProps } from "./SelectionButtonProps";

interface MultiPageSelectionProps {
  titleText: string;
  bodyText: string;
  items: SelectionButtonProps[];
  maxSelections?: number;
  onSelectionChange: (selectedItems: SelectionButtonProps[]) => void; // Callback function.
}

const MultiPageSelectionComponent = ({
  titleText,
  bodyText,
  items,
  maxSelections,
  onSelectionChange,
}: MultiPageSelectionProps) => {
  const [selectedItems, setSelectedItems] = useState<SelectionButtonProps[]>(
    [],
  );
  const setButtonEnabled = useButtonStore((state) => state.setButtonEnabled);

  const handleItemClick = (item: SelectionButtonProps) => {
    if (selectedItems.includes(item)) {
      // Deselect the item.
      setSelectedItems(
        selectedItems.filter((selectedItem) => selectedItem !== item),
      );
    } else {
      // Select the item if maxSelections not reached.
      if (!maxSelections || selectedItems.length < maxSelections) {
        setSelectedItems([...selectedItems, item]);
      } else {
        // Optionally display an error message (e.g., using a Snackbar).
        alert(`You can select up to ${maxSelections} items.`);
      }
    }
  };

  // Notify parent component of selection change. However, to avoid
  // infinite loops, we need to memoize the callback function.
  const memoizedOnSelectionChange = useCallback(onSelectionChange, []);

  useEffect(() => {
    memoizedOnSelectionChange(selectedItems);
    setButtonEnabled(selectedItems.length > 0);
  }, [selectedItems, setButtonEnabled, memoizedOnSelectionChange]);

  // Determine if we should use grid layout (more than 5 items)
  const useGridLayout = items.length > 5;

  return (
    <Box
      display="flex"
      maxWidth="420px"
      flexDirection="column"
      justifyContent="top"
      width="100%"
      overflow="auto"
      height="100%"
    >
      <FreeboardingTitle>{titleText}</FreeboardingTitle>
      <FreeboardingParagraph>{bodyText}</FreeboardingParagraph>

      {useGridLayout ? (
        <Grid
          container
          spacing={2}
          marginTop={1}
          marginBottom={2}
          width="100%"
          sx={{ overflowY: "auto" }}
        >
          {items.map((item) => (
            <Grid size={6} key={item.id}>
              <OutlinedButton
                className={selectedItems.includes(item) ? "Mui-selected" : ""}
                onClick={() => {
                  handleItemClick(item);
                }}
                sx={{ height: "100%" }}
              >
                {item.label}
              </OutlinedButton>
            </Grid>
          ))}
        </Grid>
      ) : (
        <Stack
          direction="column"
          spacing={2}
          marginTop={3}
          marginBottom={2}
          width="100%"
          sx={{ overflowY: "auto" }}
        >
          {items.map((item) => (
            <OutlinedButton
              key={item.id}
              // Conditionally apply the "selected" class based on selection.
              className={selectedItems.includes(item) ? "Mui-selected" : ""}
              onClick={() => {
                handleItemClick(item);
              }}
            >
              {item.label}
            </OutlinedButton>
          ))}
        </Stack>
      )}
    </Box>
  );
};

export default MultiPageSelectionComponent;
