import { createFileRoute } from "@tanstack/react-router";
import { useUserState } from "src/components/UserState";
import MultiPageSelectionComponent from "src/features/MultiSelectionPageComponent";

export const Route = createFileRoute("/_layout/trainingStyle")({
  component: RouteComponent,
});

function RouteComponent() {
  const setSelections = useUserState((state) => state.setSelections);

  return (
    <MultiPageSelectionComponent
      titleText="What's your preferred training style?"
      bodyText="Pick up to 3 ways you like to exercise."
      items={[
        {
          label: "Traditional Strength Training",
          id: "traditional_strength",
        },
        { label: "Functional Training (Crossfit)", id: "functional_training" },
        { label: "Yoga & Mobility", id: "yoga" },
        { label: "High Intensity Intervals (HIIT)", id: "hiit" },
        { label: "Pilates & Calisthenics", id: "calisthenics" },
      ]}
      maxSelections={3}
      onSelectionChange={(selectedItems) => {
        console.log(selectedItems);
        setSelections("/trainingStyle", selectedItems);
      }}
    />
  );
}
