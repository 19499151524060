import { Avatar, AvatarGroup, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

/**
 * Displays images.size() trainers that overlap a little. The images are followed
 * by a text description.
 */

interface TrainWithProps {
  images: string[];
  text: string;
}

const WhiteBorderAvatarGroup = styled(AvatarGroup)(() => ({
  "& .MuiAvatar-root": {
    width: 22,
    height: 22,
    border: "1px solid white", // Added a black border.
    marginLeft: "-8px", // More overlap.
  },
}));

const TrainWithContainer = ({ images, text }: TrainWithProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <WhiteBorderAvatarGroup max={images.length}>
        {images.map((image, index) => (
          <Avatar key={index} alt={`Trainer ${index + 1}`} src={image} />
        ))}
      </WhiteBorderAvatarGroup>
      <Typography variant="body2">{text}</Typography>
    </Stack>
  );
};

export default TrainWithContainer;
