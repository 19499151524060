import { Paper } from "@mui/material";
import { styled } from "@mui/material/styles";
import { trainwellColor } from "src/theme/themePrimitives";

export const WorkoutCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: theme.spacing(1),
  backgroundColor: trainwellColor.neutral2,
  //Remove shadow for the specified look
  boxShadow: "none",
  //Add border for the look.
  border: `1px solid ${trainwellColor.neutral2}`,
  display: "flex",
  alignItems: "center",
}));
