import { createFileRoute } from "@tanstack/react-router";
import { useUserState } from "src/components/UserState";
import SingleSelectionPageComponent from "src/features/SingleSelectionPageComponent";

export const Route = createFileRoute("/_layout/fitnessGoal")({
  component: RouteComponent,
});

function RouteComponent() {
  const setSelections = useUserState((state) => state.setSelections);

  return (
    <SingleSelectionPageComponent
      titleText="What's your main fitness goal?"
      bodyText="Pick the one that matters most to you."
      items={[
        { label: "Build Strength", id: "build_strength" },
        { label: "Gain Muscle", id: "gain_muscle" },
        { label: "Lose Fat", id: "lose_fat" },
        { label: "Improve Health", id: "improve_health" },
      ]}
      selectionText="Your goal helps us find the perfect trainer-crafted plan that suit your needs."
      onSelectionChange={(selectedItem) => {
        console.log(selectedItem);
        if (selectedItem !== null) {
          setSelections("/fitnessGoal", [selectedItem]);
        } else {
          setSelections("/fitnessGoal", undefined);
        }
      }}
    />
  );
}
