import { createFileRoute } from "@tanstack/react-router";
import TextPageComponent from "../features/TextPageComponent";
import TrainWithContainer from "../features/TrainWithContainer";

export const Route = createFileRoute("/_layout/intro")({
  component: RouteComponent,
});

function RouteComponent() {
  return (
    <TextPageComponent
      chipText="Exclusive invite-only access to trainwell teams"
      titleText="Expert-crafted fitness plans that fit your goals, style & schedule"
      bodyText="Get free personalized trainer-curated plans and create your exclusive team to train together."
      customComponent={
        <TrainWithContainer
          text="loved by 45,000+ members"
          images={[
            "../assets/stock-image-1.png",
            "../assets/stock-image-2.png",
            "../assets/stock-image-3.png",
          ]}
        />
      }
    />
  );
}
