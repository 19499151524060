import { Box, useMediaQuery } from "@mui/material";
import type { FreemiumPlanResponseDetailType } from "@trainwell/features/freemium-plans";
import { useEffect, useState } from "react";
import { useButtonStore } from "src/components/ButtonState";
import { useTrainingPlanState } from "src/components/TrainingPlanState";
import { SuccessBadge } from "../components/FreeboardingBadge";
import { FreeboardingParagraph } from "../components/FreeboardingParagraph";
import { FreeboardingTitle } from "../components/FreeboardingTitle";
import TrainingPlanCard from "./training-plans/TrainingPlanCard";

/**
 * Page that shows the training plan. It contains a list of TrainingPlanCards,
 * and it adjusts the layout according to the screen size.
 */

interface TrainingPlanPageProps {
  titleText: string;
  bodyText: string;
  chipText: string;
  trainingPlans: FreemiumPlanResponseDetailType[];
}

const TrainingPlanPageComponent = ({
  titleText,
  chipText,
  bodyText,
  trainingPlans,
}: TrainingPlanPageProps) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));

  const setButtonEnabled = useButtonStore((state) => state.setButtonEnabled);
  const setButtonVisible = useButtonStore((state) => state.setVisible);

  // Keep track of selected plan, so if the user goes back to this page, we select
  // the plan.
  const selectedPlan = useTrainingPlanState((plan) => plan.selectedPlan);
  const setSelectedPlan = useTrainingPlanState((plan) => plan.setSelectedPlan);
  const [selectedPlanIndex, setSelectedPlanIndex] = useState<number | null>(
    null,
  );

  useEffect(() => {
    // Notify parent component that the button should be visible.
    setButtonVisible(true);

    if (selectedPlan) {
      const index = trainingPlans.findIndex(
        (plan) => plan.plan_id === selectedPlan.plan_id,
      );
      setSelectedPlanIndex(index !== -1 ? index : null);
    }
    setButtonEnabled(selectedPlan != undefined);
  }, [selectedPlan, trainingPlans, setButtonEnabled, setButtonVisible]);

  const handleCardClick = (
    index: number,
    plan?: FreemiumPlanResponseDetailType,
  ) => {
    setSelectedPlanIndex(index === selectedPlanIndex ? null : index);
    setSelectedPlan(index === selectedPlanIndex ? undefined : plan);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <SuccessBadge label={chipText} />
      <FreeboardingTitle>{titleText}</FreeboardingTitle>
      <FreeboardingParagraph>{bodyText}</FreeboardingParagraph>

      <Box
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
        alignItems="stretch"
        sx={{ width: "100%", marginTop: 2, gap: 6 }}
      >
        {trainingPlans.map((plan, index) => (
          <Box
            key={index}
            sx={{
              flex: isMobile ? "100%" : "30%",
              display: "flex",
              flexDirection: "column",
            }}
            onClick={() => {
              handleCardClick(index, plan);
            }}
          >
            <TrainingPlanCard
              plan={plan}
              selected={index === selectedPlanIndex}
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default TrainingPlanPageComponent;
