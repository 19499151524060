import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { trainwellColor } from "../theme/themePrimitives";

/**
 * trainwell Outlined Button according to the specs. Should be reused every time
 * a outlined button is needed.
 */
export const TextButton = styled(Button)(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(2, 3),
  "&.Mui-selected": {
    backgroundColor: trainwellColor.primary1,
    border: `1px solid ${trainwellColor.primary4}`, // Set the border color and width
  },
}));
