import type { SelectionButtonProps } from "src/features/SelectionButtonProps";
import { create } from "zustand";

/**
 * This store manages the state of the user session in the app. It also
 * stores the user's selections on each page.
 */
interface UserState {
  userId?: string;
  setUserId: (userId: string) => void;
  selections: Record<string, SelectionButtonProps[]>;
  setSelections: (page: string, items?: SelectionButtonProps[]) => void;
  phoneNumber?: string | undefined;
  setPhoneNumber: (phoneNumber: string) => void;
  email?: string | undefined;
  setEmail: (email: string) => void;
  firstName?: string | undefined;
  setFirstName: (firstName: string) => void;
  lastName?: string | undefined;
  setLastName: (lastName: string) => void;
}

export const useUserState = create<UserState>((set) => ({
  userId: undefined,
  setUserId: (value) => {
    set({ userId: value });
  },
  selections: {},
  setSelections: (page: string, items?: SelectionButtonProps[]) => {
    set((state) => ({
      selections: {
        ...state.selections,
        [page]: items || [],
      },
    }));
  },
  phoneNumber: undefined,
  setPhoneNumber: (value) => {
    set({ phoneNumber: value });
  },
  email: undefined,
  setEmail: (value) => {
    set({ email: value });
  },
  firstName: undefined,
  setFirstName: (value) => {
    set({ firstName: value });
  },
  lastName: undefined,
  setLastName: (value) => {
    set({ lastName: value });
  },
}));
