import { Box } from "@mui/material";
import type { FreemiumPlanResponseDetailType } from "@trainwell/features/freemium-plans";
import { useEffect } from "react";
import { useButtonStore } from "src/components/ButtonState";
import { ErrorMessage } from "src/components/ErrorMessage";
import { useErrorState } from "src/components/ErrorState";
import { FreeboardingSubtitle } from "src/components/FreeboardingSubtitle";
import { StyledTextField } from "src/components/FreeboardingTextField";
import { useUserState } from "src/components/UserState";
import { theme } from "src/theme/theme";
import { SuccessBadge } from "../components/FreeboardingBadge";
import { FreeboardingParagraph } from "../components/FreeboardingParagraph";
import { FreeboardingTitle } from "../components/FreeboardingTitle";
import TrainerPlanCardSmall from "./training-plans/TrainerPlanCardSmall";

/**
 * This component display intake form for the user to sign up after they have selected
 * the plan they want to try. If the email is valid, they can continue.
 */

interface IntakePageProps {
  chipText: string;
  titleText: string;
  bodyText: string;
  selectedPlan?: FreemiumPlanResponseDetailType;
}

const IntakePageComponent = ({
  chipText,
  titleText,
  bodyText,
  selectedPlan,
}: IntakePageProps) => {
  const setButtonEnabled = useButtonStore((state) => state.setButtonEnabled);

  const email = useUserState((state) => state.email);
  const setEmail = useUserState((state) => state.setEmail);
  const phoneNumber = useUserState((state) => state.phoneNumber);
  const setPhoneNumber = useUserState((state) => state.setPhoneNumber);
  const firstName = useUserState((state) => state.firstName);
  const setFirstName = useUserState((state) => state.setFirstName);
  const lastName = useUserState((state) => state.lastName);
  const setLastName = useUserState((state) => state.setLastName);
  const errorMessage = useErrorState((state) => state.errorMessage);

  useEffect(() => {
    // Email is valid if it contains an @ and a .
    const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email || "");
    // Phone number is valid if it contains only numbers.
    const phoneIsValid = /\d/.test(phoneNumber || "");
    // First name is valid if it contains only letters.
    const firstNameIsValid = /[a-zA-Z]/.test(firstName || "");
    // Last name is valid if it contains only letters.
    const lastNameIsValid = /[a-zA-Z]/.test(lastName || "");

    setButtonEnabled(
      emailIsValid && phoneIsValid && firstNameIsValid && lastNameIsValid,
    );
  }, [firstName, lastName, email, phoneNumber, setButtonEnabled]);

  return (
    <Box
      display="flex"
      maxWidth="720px"
      flexDirection="column"
      alignItems="center"
      width="100%"
    >
      <SuccessBadge sx={{ marginBottom: theme.spacing(2) }} label={chipText} />
      {/* TODO(akrulec): Fix null state here. */}
      {selectedPlan && <TrainerPlanCardSmall plan={selectedPlan} />}
      <FreeboardingTitle>{titleText}</FreeboardingTitle>
      <FreeboardingParagraph>{bodyText}</FreeboardingParagraph>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="start"
        width="100%"
        marginBottom={theme.spacing(4)}
      >
        <FreeboardingSubtitle>First Name</FreeboardingSubtitle>
        <StyledTextField
          value={firstName || ""}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
        />
        <FreeboardingSubtitle>Last Name</FreeboardingSubtitle>
        <StyledTextField
          value={lastName || ""}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
        <FreeboardingSubtitle>Email</FreeboardingSubtitle>
        <StyledTextField
          value={email || ""}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
        />
        <FreeboardingSubtitle>Phone Number</FreeboardingSubtitle>
        <StyledTextField
          value={phoneNumber || ""}
          onChange={(e) => {
            setPhoneNumber(e.target.value);
          }}
          placeholder="+1 (123) 456-7890"
          slotProps={{
            htmlInput: {
              inputMode: "numeric",
              maxLength: 14,
            },
          }}
        />
        {/* I would like to display an error, if there is one to display */}
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </Box>
    </Box>
  );
};

export default IntakePageComponent;
