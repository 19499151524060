import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Box, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { trainwellColor } from "src/theme/themePrimitives";
import { useButtonStore } from "../components/ButtonState";
import { FreeboardingParagraph } from "../components/FreeboardingParagraph";
import { FreeboardingTitle } from "../components/FreeboardingTitle";
import { OutlinedButton } from "../components/OutlinedButton";
import OutlinedCard from "../components/OutlinedCard";
import type { SelectionButtonProps } from "./SelectionButtonProps";

interface SingleSelectionPageProps {
  titleText: string;
  bodyText: string;
  items: SelectionButtonProps[];
  selectionText?: string;
  onSelectionChange: (selectedItem: SelectionButtonProps | null) => void; // Callback function.
}

const SingleSelectionPageComponent = ({
  titleText,
  bodyText,
  items,
  selectionText,
  onSelectionChange,
}: SingleSelectionPageProps) => {
  const [selectedItem, setSelectedItem] = useState<SelectionButtonProps | null>(
    null,
  );
  const setButtonEnabled = useButtonStore((state) => state.setButtonEnabled);

  const handleItemClick = (item: SelectionButtonProps) => {
    if (selectedItem?.id === item.id) {
      // Deselect the item.
      setSelectedItem(null);
    } else {
      // Select the item.
      setSelectedItem(item);
    }
  };

  // Notify parent component of selection change. However, to avoid
  // infinite loops, we need to memoize the callback function.
  const memoizedOnSelectionChange = useCallback(onSelectionChange, []);

  useEffect(() => {
    memoizedOnSelectionChange(selectedItem);
    setButtonEnabled(selectedItem != null);
  }, [selectedItem, setButtonEnabled, memoizedOnSelectionChange]);

  return (
    <Box
      display="flex"
      maxWidth="420px"
      flexDirection="column"
      justifyContent="space-between"
      width="100%"
      height="100%"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        width="100%"
      >
        <FreeboardingTitle>{titleText}</FreeboardingTitle>
        <FreeboardingParagraph>{bodyText}</FreeboardingParagraph>

        <Stack
          direction="column"
          spacing={2}
          marginTop={3}
          marginBottom={2}
          width="100%"
        >
          {items.map((item) => (
            <OutlinedButton
              key={item.id}
              // Conditionally apply the "selected" class based on selection.
              className={selectedItem?.id === item.id ? "Mui-selected" : ""}
              onClick={() => {
                handleItemClick(item);
              }}
              fullWidth
            >
              {item.label}
            </OutlinedButton>
          ))}
        </Stack>
      </Box>

      {selectedItem && selectionText && (
        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <OutlinedCard
            text={selectionText}
            icon={
              <AccessTimeFilledIcon sx={{ color: trainwellColor.success3 }} />
            }
            cardColor={trainwellColor.success1}
            borderColor={trainwellColor.success3}
          />
        </Box>
      )}
    </Box>
  );
};

export default SingleSelectionPageComponent;
