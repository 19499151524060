import { Box, Container, useTheme } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";
import type { FreemiumPlanResponseDetailType } from "@trainwell/features/freemium-plans";
import { CoachAvatar } from "src/components/CoachAvatar";
import { PrimaryBadge } from "src/components/FreeboardingBadge";
import { TextButton } from "src/components/TextButton";
import { CreatedByTitle } from "src/components/training-plans/CreatedByTitle";
import { PlanDescription } from "src/components/training-plans/PlanDescription";
import { PlanSubtitle } from "src/components/training-plans/PlanSubtitle";
import { PlanTitle } from "src/components/training-plans/PlanTitle";
import { useTrainingPlanState } from "src/components/TrainingPlanState";
import { trainwellColor } from "src/theme/themePrimitives";
import TrainWithContainer from "../TrainWithContainer";

interface TrainerCardProps {
  plan: FreemiumPlanResponseDetailType;
  selected?: boolean;
}

const TrainingPlanCard = ({ plan, selected }: TrainerCardProps) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const setSelectedPlan = useTrainingPlanState(
    (state) => state.setSelectedPlan,
  );

  const handleLearnMoreClick = (event: React.MouseEvent) => {
    // Stop the event from propagating to the parent box. This avoids user de-selecting
    // the plan when they click Learn More button.
    event.stopPropagation();
    setSelectedPlan(plan);
    navigate({
      to: "/trainingPlanDetails",
    });
  };

  return (
    <Container
      sx={{
        display: "flex",
        width: "100%",
        maxWidth: "300px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        position: "relative",
        borderRadius: theme.spacing(2),
        border: `2px solid ${selected ? trainwellColor.primary3 : trainwellColor.primary1}`,
        height: "100%",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
      }}
    >
      <Box
        component="img"
        src={plan.summary.gradient_image_url}
        alt="Background"
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "80px",
          objectFit: "cover",
          flexShrink: "0",
          borderTopLeftRadius: theme.spacing(2),
          borderTopRightRadius: theme.spacing(2),
        }}
      />
      <Box
        sx={{
          position: "relative",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CoachAvatar
          src={plan.trainer.headshot_url}
          alt="Creator Avatar"
          sx={{
            marginTop: theme.spacing(2),
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
          }}
        />
        <CreatedByTitle>{`Created by ${plan.trainer.first_name}`}</CreatedByTitle>
        <PlanTitle>{plan.summary.title}</PlanTitle>
        <PlanSubtitle>{plan.summary.description_short}</PlanSubtitle>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            marginBottom: theme.spacing(2),
          }}
        >
          {plan.plan.tags.map((tag) => (
            <PrimaryBadge
              label={tag}
              key={tag}
              style={{
                marginRight: theme.spacing(1),
                marginBottom: theme.spacing(1),
              }}
            />
          ))}
        </Box>
        <PlanDescription>{plan.summary.description_long}</PlanDescription>
        <TrainWithContainer
          text="+230 trained with Darian"
          images={[
            "../assets/stock-image-1.png",
            "../assets/stock-image-2.png",
            "../assets/stock-image-3.png",
          ]}
        />
        <TextButton
          sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }}
          onClick={handleLearnMoreClick}
        >
          Learn More
        </TextButton>
      </Box>
    </Container>
  );
};

export default TrainingPlanCard;
