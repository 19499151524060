import { Box, Card, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import type { ReactNode } from "react";
import React from "react";

interface OutlinedCardProps {
  text: string;
  cardColor: string;
  borderColor: string;
  icon?: ReactNode;
}

const StyledCard = styled(Card)<{ borderColor: string; cardColor?: string }>(
  ({ theme, borderColor, cardColor }) => ({
    border: `1.4px solid ${borderColor}`,
    backgroundColor: cardColor,
    borderRadius: theme.spacing(1),
  }),
);

const OutlinedCard: React.FC<OutlinedCardProps> = ({
  text,
  cardColor,
  borderColor,
  icon,
}: OutlinedCardProps) => {
  return (
    <StyledCard
      variant="outlined"
      borderColor={borderColor}
      cardColor={cardColor}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          p: 2,
        }}
      >
        <Box mr={2} display="flex" alignItems="center">
          {icon}
        </Box>
        <Typography variant="body2" alignItems="center">
          {text}
        </Typography>
      </Box>
    </StyledCard>
  );
};

export default OutlinedCard;
