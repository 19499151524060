import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import { Box } from "@mui/material";
import { useEffect, type ReactNode } from "react";
import { useButtonStore } from "src/components/ButtonState";
import { SuccessBadge } from "../components/FreeboardingBadge";
import { FreeboardingParagraph } from "../components/FreeboardingParagraph";
import { FreeboardingTitle } from "../components/FreeboardingTitle";

/**
 * This component displays text pages with a chip, title, paragraph, and a lottie
 * animation in the center. At the bottom, it has an optional custom component that
 * can be anything.
 */

interface LottiePageProps {
  chipText: string;
  titleText: string;
  bodyText: string;
  customComponent?: ReactNode;
}

const LottiePageComponent = ({
  chipText,
  titleText,
  bodyText,
  customComponent,
}: LottiePageProps) => {
  const setButtonVisible = useButtonStore((state) => state.setVisible);

  // Notify parent component that the button should not be visible.
  useEffect(() => {
    setButtonVisible(false);
  }, [setButtonVisible]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%">
      <SuccessBadge label={chipText} />
      <FreeboardingTitle>{titleText}</FreeboardingTitle>
      <FreeboardingParagraph>{bodyText}</FreeboardingParagraph>
      {/* More information about setting Lottie properties. */}
      {/* https://developers.lottiefiles.com/docs/dotlottie-player/dotlottie-react/properties/ */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DotLottieReact
          src="../assets/lotties/training_plan_loader.lottie"
          loop
          autoplay
        />
      </Box>
      <Box marginTop={2}>{customComponent}</Box>
    </Box>
  );
};

export default LottiePageComponent;
