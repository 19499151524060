import { createFileRoute } from "@tanstack/react-router";
import { useFreemiumPhaseDetails } from "@trainwell/features/freemium-plans";
import { useTrainingPlanState } from "src/components/TrainingPlanState";
import TrainingPlanDetailsPageComponent from "src/features/plan-details/TrainingPlanDetailsPageComponent";

export const Route = createFileRoute("/_layout/trainingPlanDetails")({
  component: RouteComponent,
});

function RouteComponent() {
  const selectedPlan = useTrainingPlanState((plan) => plan.selectedPlan);

  const {
    data: phaseDetails,
    isError,
    isPending,
    error,
  } = useFreemiumPhaseDetails({
    filter: {
      planId: selectedPlan?.plan.plan_template_id || "",
      phaseId: selectedPlan?.plan.training_plan_phases[0].id?.toString() || "",
      detailed: true,
    },
  });

  console.log("phaseDetails", phaseDetails);
  console.log(isError, ": error: ", error);

  if (isPending) {
    return <div>Loading...</div>;
  }

  // TODO(akrulec): Handle this empty state better.
  return selectedPlan ? (
    <TrainingPlanDetailsPageComponent
      plan={selectedPlan}
      phaseDetails={phaseDetails}
    />
  ) : null;
}
