/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as LayoutImport } from './routes/_layout'
import { Route as LayoutIndexImport } from './routes/_layout.index'
import { Route as LayoutTrainingStyleImport } from './routes/_layout.trainingStyle'
import { Route as LayoutTrainingPlansImport } from './routes/_layout.trainingPlans'
import { Route as LayoutTrainingPlanDetailsImport } from './routes/_layout.trainingPlanDetails'
import { Route as LayoutTrainerGenderImport } from './routes/_layout.trainerGender'
import { Route as LayoutSignUpImport } from './routes/_layout.signUp'
import { Route as LayoutNumDaysImport } from './routes/_layout.numDays'
import { Route as LayoutIntroImport } from './routes/_layout.intro'
import { Route as LayoutFitnessGoalImport } from './routes/_layout.fitnessGoal'
import { Route as LayoutEquipmentImport } from './routes/_layout.equipment'
import { Route as LayoutDurationImport } from './routes/_layout.duration'

// Create/Update Routes

const LayoutRoute = LayoutImport.update({
  id: '/_layout',
  getParentRoute: () => rootRoute,
} as any)

const LayoutIndexRoute = LayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTrainingStyleRoute = LayoutTrainingStyleImport.update({
  id: '/trainingStyle',
  path: '/trainingStyle',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTrainingPlansRoute = LayoutTrainingPlansImport.update({
  id: '/trainingPlans',
  path: '/trainingPlans',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTrainingPlanDetailsRoute = LayoutTrainingPlanDetailsImport.update({
  id: '/trainingPlanDetails',
  path: '/trainingPlanDetails',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutTrainerGenderRoute = LayoutTrainerGenderImport.update({
  id: '/trainerGender',
  path: '/trainerGender',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutSignUpRoute = LayoutSignUpImport.update({
  id: '/signUp',
  path: '/signUp',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutNumDaysRoute = LayoutNumDaysImport.update({
  id: '/numDays',
  path: '/numDays',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutIntroRoute = LayoutIntroImport.update({
  id: '/intro',
  path: '/intro',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutFitnessGoalRoute = LayoutFitnessGoalImport.update({
  id: '/fitnessGoal',
  path: '/fitnessGoal',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutEquipmentRoute = LayoutEquipmentImport.update({
  id: '/equipment',
  path: '/equipment',
  getParentRoute: () => LayoutRoute,
} as any)

const LayoutDurationRoute = LayoutDurationImport.update({
  id: '/duration',
  path: '/duration',
  getParentRoute: () => LayoutRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_layout': {
      id: '/_layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof LayoutImport
      parentRoute: typeof rootRoute
    }
    '/_layout/duration': {
      id: '/_layout/duration'
      path: '/duration'
      fullPath: '/duration'
      preLoaderRoute: typeof LayoutDurationImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/equipment': {
      id: '/_layout/equipment'
      path: '/equipment'
      fullPath: '/equipment'
      preLoaderRoute: typeof LayoutEquipmentImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/fitnessGoal': {
      id: '/_layout/fitnessGoal'
      path: '/fitnessGoal'
      fullPath: '/fitnessGoal'
      preLoaderRoute: typeof LayoutFitnessGoalImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/intro': {
      id: '/_layout/intro'
      path: '/intro'
      fullPath: '/intro'
      preLoaderRoute: typeof LayoutIntroImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/numDays': {
      id: '/_layout/numDays'
      path: '/numDays'
      fullPath: '/numDays'
      preLoaderRoute: typeof LayoutNumDaysImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/signUp': {
      id: '/_layout/signUp'
      path: '/signUp'
      fullPath: '/signUp'
      preLoaderRoute: typeof LayoutSignUpImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/trainerGender': {
      id: '/_layout/trainerGender'
      path: '/trainerGender'
      fullPath: '/trainerGender'
      preLoaderRoute: typeof LayoutTrainerGenderImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/trainingPlanDetails': {
      id: '/_layout/trainingPlanDetails'
      path: '/trainingPlanDetails'
      fullPath: '/trainingPlanDetails'
      preLoaderRoute: typeof LayoutTrainingPlanDetailsImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/trainingPlans': {
      id: '/_layout/trainingPlans'
      path: '/trainingPlans'
      fullPath: '/trainingPlans'
      preLoaderRoute: typeof LayoutTrainingPlansImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/trainingStyle': {
      id: '/_layout/trainingStyle'
      path: '/trainingStyle'
      fullPath: '/trainingStyle'
      preLoaderRoute: typeof LayoutTrainingStyleImport
      parentRoute: typeof LayoutImport
    }
    '/_layout/': {
      id: '/_layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof LayoutIndexImport
      parentRoute: typeof LayoutImport
    }
  }
}

// Create and export the route tree

interface LayoutRouteChildren {
  LayoutDurationRoute: typeof LayoutDurationRoute
  LayoutEquipmentRoute: typeof LayoutEquipmentRoute
  LayoutFitnessGoalRoute: typeof LayoutFitnessGoalRoute
  LayoutIntroRoute: typeof LayoutIntroRoute
  LayoutNumDaysRoute: typeof LayoutNumDaysRoute
  LayoutSignUpRoute: typeof LayoutSignUpRoute
  LayoutTrainerGenderRoute: typeof LayoutTrainerGenderRoute
  LayoutTrainingPlanDetailsRoute: typeof LayoutTrainingPlanDetailsRoute
  LayoutTrainingPlansRoute: typeof LayoutTrainingPlansRoute
  LayoutTrainingStyleRoute: typeof LayoutTrainingStyleRoute
  LayoutIndexRoute: typeof LayoutIndexRoute
}

const LayoutRouteChildren: LayoutRouteChildren = {
  LayoutDurationRoute: LayoutDurationRoute,
  LayoutEquipmentRoute: LayoutEquipmentRoute,
  LayoutFitnessGoalRoute: LayoutFitnessGoalRoute,
  LayoutIntroRoute: LayoutIntroRoute,
  LayoutNumDaysRoute: LayoutNumDaysRoute,
  LayoutSignUpRoute: LayoutSignUpRoute,
  LayoutTrainerGenderRoute: LayoutTrainerGenderRoute,
  LayoutTrainingPlanDetailsRoute: LayoutTrainingPlanDetailsRoute,
  LayoutTrainingPlansRoute: LayoutTrainingPlansRoute,
  LayoutTrainingStyleRoute: LayoutTrainingStyleRoute,
  LayoutIndexRoute: LayoutIndexRoute,
}

const LayoutRouteWithChildren =
  LayoutRoute._addFileChildren(LayoutRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof LayoutRouteWithChildren
  '/duration': typeof LayoutDurationRoute
  '/equipment': typeof LayoutEquipmentRoute
  '/fitnessGoal': typeof LayoutFitnessGoalRoute
  '/intro': typeof LayoutIntroRoute
  '/numDays': typeof LayoutNumDaysRoute
  '/signUp': typeof LayoutSignUpRoute
  '/trainerGender': typeof LayoutTrainerGenderRoute
  '/trainingPlanDetails': typeof LayoutTrainingPlanDetailsRoute
  '/trainingPlans': typeof LayoutTrainingPlansRoute
  '/trainingStyle': typeof LayoutTrainingStyleRoute
  '/': typeof LayoutIndexRoute
}

export interface FileRoutesByTo {
  '/duration': typeof LayoutDurationRoute
  '/equipment': typeof LayoutEquipmentRoute
  '/fitnessGoal': typeof LayoutFitnessGoalRoute
  '/intro': typeof LayoutIntroRoute
  '/numDays': typeof LayoutNumDaysRoute
  '/signUp': typeof LayoutSignUpRoute
  '/trainerGender': typeof LayoutTrainerGenderRoute
  '/trainingPlanDetails': typeof LayoutTrainingPlanDetailsRoute
  '/trainingPlans': typeof LayoutTrainingPlansRoute
  '/trainingStyle': typeof LayoutTrainingStyleRoute
  '/': typeof LayoutIndexRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_layout': typeof LayoutRouteWithChildren
  '/_layout/duration': typeof LayoutDurationRoute
  '/_layout/equipment': typeof LayoutEquipmentRoute
  '/_layout/fitnessGoal': typeof LayoutFitnessGoalRoute
  '/_layout/intro': typeof LayoutIntroRoute
  '/_layout/numDays': typeof LayoutNumDaysRoute
  '/_layout/signUp': typeof LayoutSignUpRoute
  '/_layout/trainerGender': typeof LayoutTrainerGenderRoute
  '/_layout/trainingPlanDetails': typeof LayoutTrainingPlanDetailsRoute
  '/_layout/trainingPlans': typeof LayoutTrainingPlansRoute
  '/_layout/trainingStyle': typeof LayoutTrainingStyleRoute
  '/_layout/': typeof LayoutIndexRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/duration'
    | '/equipment'
    | '/fitnessGoal'
    | '/intro'
    | '/numDays'
    | '/signUp'
    | '/trainerGender'
    | '/trainingPlanDetails'
    | '/trainingPlans'
    | '/trainingStyle'
    | '/'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/duration'
    | '/equipment'
    | '/fitnessGoal'
    | '/intro'
    | '/numDays'
    | '/signUp'
    | '/trainerGender'
    | '/trainingPlanDetails'
    | '/trainingPlans'
    | '/trainingStyle'
    | '/'
  id:
    | '__root__'
    | '/_layout'
    | '/_layout/duration'
    | '/_layout/equipment'
    | '/_layout/fitnessGoal'
    | '/_layout/intro'
    | '/_layout/numDays'
    | '/_layout/signUp'
    | '/_layout/trainerGender'
    | '/_layout/trainingPlanDetails'
    | '/_layout/trainingPlans'
    | '/_layout/trainingStyle'
    | '/_layout/'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  LayoutRoute: typeof LayoutRouteWithChildren
}

const rootRouteChildren: RootRouteChildren = {
  LayoutRoute: LayoutRouteWithChildren,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_layout"
      ]
    },
    "/_layout": {
      "filePath": "_layout.tsx",
      "children": [
        "/_layout/duration",
        "/_layout/equipment",
        "/_layout/fitnessGoal",
        "/_layout/intro",
        "/_layout/numDays",
        "/_layout/signUp",
        "/_layout/trainerGender",
        "/_layout/trainingPlanDetails",
        "/_layout/trainingPlans",
        "/_layout/trainingStyle",
        "/_layout/"
      ]
    },
    "/_layout/duration": {
      "filePath": "_layout.duration.tsx",
      "parent": "/_layout"
    },
    "/_layout/equipment": {
      "filePath": "_layout.equipment.tsx",
      "parent": "/_layout"
    },
    "/_layout/fitnessGoal": {
      "filePath": "_layout.fitnessGoal.tsx",
      "parent": "/_layout"
    },
    "/_layout/intro": {
      "filePath": "_layout.intro.tsx",
      "parent": "/_layout"
    },
    "/_layout/numDays": {
      "filePath": "_layout.numDays.tsx",
      "parent": "/_layout"
    },
    "/_layout/signUp": {
      "filePath": "_layout.signUp.tsx",
      "parent": "/_layout"
    },
    "/_layout/trainerGender": {
      "filePath": "_layout.trainerGender.tsx",
      "parent": "/_layout"
    },
    "/_layout/trainingPlanDetails": {
      "filePath": "_layout.trainingPlanDetails.tsx",
      "parent": "/_layout"
    },
    "/_layout/trainingPlans": {
      "filePath": "_layout.trainingPlans.tsx",
      "parent": "/_layout"
    },
    "/_layout/trainingStyle": {
      "filePath": "_layout.trainingStyle.tsx",
      "parent": "/_layout"
    },
    "/_layout/": {
      "filePath": "_layout.index.tsx",
      "parent": "/_layout"
    }
  }
}
ROUTE_MANIFEST_END */
