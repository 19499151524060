import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";
import { trainwellColor } from "../theme/themePrimitives";

/**
 * Badge used on most of the freeboarding screens. There are four variants
 * of the original Chip elements: green, purple, neutral, and blue.
 */

const BaseFreeboardingChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  padding: theme.spacing(0),
  "& .MuiChip-label": {
    ...theme.typography.caption,
  },
}));

/** Success Badge */
export const SuccessBadge = styled(BaseFreeboardingChip)(() => ({
  backgroundColor: trainwellColor.success1,
  "& .MuiChip-label": {
    color: trainwellColor.success3,
  },
}));

/** Neutral Badge */
export const NeutralBadge = styled(BaseFreeboardingChip)(() => ({
  backgroundColor: trainwellColor.neutral2,
  "& .MuiChip-label": {
    color: trainwellColor.neutral6,
  },
}));

/** Primary Badge */
export const PrimaryBadge = styled(BaseFreeboardingChip)(() => ({
  backgroundColor: trainwellColor.primary1,
  "& .MuiChip-label": {
    color: trainwellColor.primary3,
  },
}));

/** Secondary Badge */
export const SecondaryBadge = styled(BaseFreeboardingChip)(() => ({
  backgroundColor: trainwellColor.secondary1,
  "& .MuiChip-label": {
    color: trainwellColor.secondary4,
  },
}));
