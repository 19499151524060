import type { FreemiumPlanResponseDetailType } from "@trainwell/features/freemium-plans";
import { create } from "zustand";

/** This store manages the state of the selected training plan. */
interface TrainingPlanState {
  selectedPlan: FreemiumPlanResponseDetailType | undefined;
  setSelectedPlan: (plan?: FreemiumPlanResponseDetailType) => void;
}

export const useTrainingPlanState = create<TrainingPlanState>((set) => ({
  selectedPlan: undefined,
  setSelectedPlan: (plan) => {
    set({ selectedPlan: plan });
  },
}));
