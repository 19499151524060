import { styled } from "@mui/material/styles";
import { trainwellColor } from "../theme/themePrimitives";

/** Subtitle used for most of the freeboarding screens. */
export const FreeboardingSubtitle = styled("h6")(({ theme }) => ({
  color: trainwellColor.neutral8,
  ...theme.typography.h6,
  marginTop: theme.spacing(3),
  marginBottom: theme.spacing(2),
}));
