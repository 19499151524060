import { Avatar } from "@mui/material";
import { styled } from "@mui/material/styles";
import { trainwellColor } from "src/theme/themePrimitives";

/**
 * Coach avatar according to the specs. It contains an image, and a
 * 3px border.
 */
export const CoachAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(10), // 80px
  height: theme.spacing(10), // 80px
  border: `3px solid ${trainwellColor.primary3}`,
}));
