import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { trainwellColor } from "../theme/themePrimitives";

/**
 * trainwell Filled Button according to the specs. Should be reused every time
 * a filled button is needed.
 */
export const FilledButton = styled(Button)(({ theme }) => ({
  backgroundColor: trainwellColor.primary4,
  color: trainwellColor.neutral1,
  padding: theme.spacing(2, 3),
  borderRadius: theme.shape.borderRadius,
  width: "100%",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
    color: trainwellColor.neutral1,
  },
  "&:active": {
    backgroundColor: theme.palette.primary.dark,
    color: trainwellColor.neutral1,
  },
  "&:disabled": {
    backgroundColor: trainwellColor.neutral4,
    color: trainwellColor.neutral6,
  },
}));
