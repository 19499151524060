import { styled } from "@mui/material/styles";
import { trainwellColor } from "src/theme/themePrimitives";

export const PlanSubtitle = styled("p")(({ theme }) => ({
  color: trainwellColor.neutral8,
  ...theme.typography.body2,
  marginTop: theme.spacing(0),
  marginBottom: theme.spacing(3),
  textAlign: "center",
}));
