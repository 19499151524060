import { CssBaseline, ThemeProvider } from "@mui/material";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createRouter } from "@tanstack/react-router";
import { updateApiOptions } from "@trainwell/features";
import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import { config } from "./config/config";
import { routeTree } from "./routeTree.gen";
import { theme } from "./theme/theme";

export const router = createRouter({
  routeTree,
  defaultPreload: "intent",
  // Since we're using React Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  defaultPreloadStaleTime: 0,
});

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const rootElement = document.getElementById("root");

if (!rootElement) {
  throw new Error("Could not find root element");
}

updateApiOptions({
  prefixUrl: config.VITE_API_DOMAIN,
  headers: {
    "api-key": config.VITE_TRAINWELL_API_KEY,
  },
});

const queryClient = new QueryClient();

if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(
    <StrictMode>
      <Suspense fallback={null}>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <App />
          </ThemeProvider>
        </QueryClientProvider>
      </Suspense>
    </StrictMode>,
  );
}
