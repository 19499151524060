import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { trainwellColor } from "src/theme/themePrimitives";

export const StepDisplay = styled("div")<{ active: boolean }>(
  ({ theme, active }) => ({
    backgroundColor: trainwellColor.neutral3,
    border: "none",
    borderRadius: theme.spacing(1),
    margin: theme.spacing(0, 1),
    width: theme.spacing(4),
    // Use theme spacing for consistent height. Limit the height to 4px.
    height: theme.spacing(0.5),
    minHeight: theme.spacing(0.5),
    maxHeight: theme.spacing(0.5),
    textAlign: "center",
    color: trainwellColor.neutral1,
    ...(active && {
      backgroundColor: trainwellColor.primary4,
    }),
  }),
);

export const ProgressBarContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  overflowX: "auto",
  padding: theme.spacing(1),
  "::-webkit-scrollbar": {
    display: "none",
  },
  msOverflowStyle: "none",
  scrollbarWidth: "none",
}));
