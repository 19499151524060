import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { trainwellColor } from "src/theme/themePrimitives";

const ErrorText = styled("p")(({ theme }) => ({
  color: trainwellColor.error3,
  ...theme.typography.body1,
  margin: 0,
  marginLeft: theme.spacing(1),
}));

interface ErrorMessageProps {
  message: string;
}

export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      sx={{
        marginTop: (theme) => theme.spacing(1),
        marginBottom: (theme) => theme.spacing(1),
      }}
    >
      <ErrorOutlineIcon color="error" />
      <ErrorText>{message}</ErrorText>
    </Box>
  );
};
