import { Box } from "@mui/material";
import {
  ProgressBarContainer,
  StepDisplay,
} from "src/components/FreeboardingSlider";

interface ProgressBarProps {
  steps: number;
  currentStep: number;
  onChangeStep: (step: number) => void;
}

const ProgressBar = ({ steps, currentStep }: ProgressBarProps) => {
  const stepNumbers = Array.from({ length: steps }, (_, i) => i + 1);

  return (
    <Box>
      <ProgressBarContainer>
        {stepNumbers.map((step) => (
          <StepDisplay key={step} active={step === currentStep} />
        ))}
      </ProgressBarContainer>
    </Box>
  );
};

export default ProgressBar;
