import { create } from "zustand";

/** This store manages the state of the next button in the app. */
interface ButtonState {
  isButtonEnabled: boolean;
  setButtonEnabled: (enabled: boolean) => void;
  isVisible: boolean;
  setVisible: (visible: boolean) => void;
}

export const useButtonStore = create<ButtonState>((set) => ({
  isButtonEnabled: true,
  setButtonEnabled: (enabled) => {
    set({ isButtonEnabled: enabled });
  },
  isVisible: true,
  setVisible: (visible) => {
    set({ isVisible: visible });
  },
}));
