/**
 * This component is used to keep state of an error message. This will have
 * an error if the server returnes one. The caller of the function is responsible
 * for clearing the error state.
 */

import { create } from "zustand";

interface ErrorState {
  errorMessage: string | undefined;
  setErrorMessage: (message: string | undefined) => void;
}

export const useErrorState = create<ErrorState>((set) => ({
  errorMessage: undefined,
  setErrorMessage: (message: string | undefined) => {
    set({ errorMessage: message });
  },
}));
