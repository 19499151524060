import { createFileRoute } from "@tanstack/react-router";
import TextPageComponent from "../features/TextPageComponent";
import TrainWithContainer from "../features/TrainWithContainer";

export const Route = createFileRoute("/_layout/")({
  component: HomePage,
});

function HomePage() {
  return (
    <TextPageComponent
      titleText="Get personalized workouts and be consistent with your friends."
      bodyText="We'll match you with the perfect workout plan and help you build a team of your friends."
      customComponent={
        <TrainWithContainer
          text="loved by 45,000+ members"
          images={[
            "../assets/stock-image-1.png",
            "../assets/stock-image-2.png",
            "../assets/stock-image-3.png",
          ]}
        />
      }
    />
  );
}
