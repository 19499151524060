import { createFileRoute } from "@tanstack/react-router";
import { EquipmentType } from "@trainwell/features/legacy";
import { useUserState } from "src/components/UserState";
import MultiPageSelectionComponent from "src/features/MultiSelectionPageComponent";

export const Route = createFileRoute("/_layout/equipment")({
  component: RouteComponent,
});

function RouteComponent() {
  const setSelections = useUserState((state) => state.setSelections);

  return (
    <MultiPageSelectionComponent
      titleText="What equipment do you have?"
      bodyText="Select what equipment you have access to, or hit continue if you have no equipment."
      items={[
        ...Object.entries(EquipmentType).map(([id, label]) => ({
          label,
          id,
        })),
      ]}
      onSelectionChange={(selectedItems) => {
        console.log(selectedItems);
        setSelections("/equipment", selectedItems);
      }}
    />
  );
}
