import { createFileRoute } from "@tanstack/react-router";
import { useTrainingPlanState } from "src/components/TrainingPlanState";
import IntakePageComponent from "src/features/IntakePageComponent";

export const Route = createFileRoute("/_layout/signUp")({
  component: RouteComponent,
});

function RouteComponent() {
  const selectedPlan = useTrainingPlanState((plan) => plan.selectedPlan);

  return (
    <IntakePageComponent
      chipText="You’ve selected this plan"
      titleText="Create your training space"
      bodyText="We'll set up your training plan in the Trainwell app once you create your account."
      selectedPlan={selectedPlan}
    />
  );
}
