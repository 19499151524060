import { Box, Container, useTheme } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  createFileRoute,
  Outlet,
  useLocation,
  useNavigate,
} from "@tanstack/react-router";
import {
  useEnrollFreemium,
  useStartSession,
  useUpdateSurveyData,
  type SurveyData,
} from "@trainwell/features/freemium-plans";
import { useEffect, useState } from "react";
import { useErrorState } from "src/components/ErrorState";
import { useTrainingPlanState } from "src/components/TrainingPlanState";
import { useUserState } from "src/components/UserState";
import ProgressBar from "src/features/ProgressBar";
import type { SelectionButtonProps } from "src/features/SelectionButtonProps";
import { useButtonStore } from "../components/ButtonState";
import { FilledButton } from "../components/FilledButton";

export const Route = createFileRoute("/_layout")({
  component: LayoutComponent,
});

function LayoutComponent() {
  const { data, error } = useStartSession({
    startSessionData: {
      platform: "web_freemium",
    },
  });

  const { mutate: updateSurveyData } = useUpdateSurveyData({
    mutationConfig: {
      onSuccess: (data) => {
        console.log("Survey data updated successfully:", data);
      },
      onError: (error) => {
        console.error("Error updating survey data:", error);
      },
    },
  });

  const createFreemium = useEnrollFreemium();

  const theme = useTheme();

  const navigate = useNavigate();
  const location = useLocation();

  const [buttonText, setButtonText] = useState("Continue");
  const isButtonEnabled = useButtonStore((state) => state.isButtonEnabled);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const isButtonVisible = useButtonStore((state) => state.isVisible);
  const setUserState = useUserState((state) => state.setUserId);
  const selections = useUserState((state) => state.selections);
  const selectedPlan = useTrainingPlanState((state) => state.selectedPlan);
  const firstName = useUserState((state) => state.firstName);
  const lastName = useUserState((state) => state.lastName);
  const email = useUserState((state) => state.email);
  const phoneNumber = useUserState((state) => state.phoneNumber);
  const [currentStep, setCurrentStep] = useState(1);
  const [showProgressBar, setShowProgressBar] = useState(false);
  const setErrorMessage = useErrorState((state) => state.setErrorMessage);

  // Save user ID that was returned when analytics session was started.
  useEffect(() => {
    if (data) {
      console.log("Session started:", data);
      setUserState(data.userId);
      sessionStorage.setItem("userId", data.userId);
    }
    if (error) {
      console.error("Error starting session:", error);
    }
  }, [data, error, setUserState]);

  // Decide which text to show.
  useEffect(() => {
    const currentPath = window.location.pathname;

    // Decide which text to show on the button.
    if (currentPath === "/" || currentPath === "/intro") {
      setButtonText("Get started");
    } else if (currentPath === "/trainingPlanDetails") {
      setButtonText("Start this plan");
    } else if (currentPath === "/signUp") {
      setButtonText("Get my plan");
    } else {
      setButtonText("Continue");
    }

    // Decide which image to show on the left side of the screen.
    if (currentPath === "/" || currentPath === "/intro") {
      setImageUrl("/assets/onboarding-images/page-1.png");
      setShowProgressBar(false);
    } else if (currentPath === "/trainingStyle") {
      setImageUrl("/assets/onboarding-images/page-2.png");
      setShowProgressBar(true);
      setCurrentStep(1);
    } else if (currentPath === "/equipment") {
      setImageUrl("/assets/onboarding-images/page-3.png");
      setShowProgressBar(true);
      setCurrentStep(2);
    } else if (currentPath === "/duration") {
      setImageUrl("/assets/onboarding-images/page-4.png");
      setShowProgressBar(true);
      setCurrentStep(3);
    } else if (currentPath === "/numDays") {
      setImageUrl("/assets/onboarding-images/page-4.png");
      setShowProgressBar(true);
      setCurrentStep(4);
    } else if (currentPath === "/trainerGender") {
      setImageUrl("/assets/onboarding-images/page-5.png");
      setShowProgressBar(true);
      setCurrentStep(5);
    } else if (currentPath === "/fitnessGoal") {
      setImageUrl("/assets/onboarding-images/page-6.png");
      setShowProgressBar(true);
      setCurrentStep(6);
    } else {
      setShowProgressBar(false);
      setImageUrl(null);
    }
  }, [location.pathname]);

  // Function to map selections to the correct survey data structure.
  const mapSelectionsToSurveyData = (currentPath: string): any => {
    const surveyData: SurveyData = {};

    if (currentPath === "/trainingStyle") {
      surveyData.freemiumTrainingStyle = mapTrainingStyle(
        selections["/trainingStyle"],
      );
    } else if (currentPath === "/equipment") {
      surveyData.freemiumEquipment =
        selections["/equipment"]?.map((equipment) => equipment.id) || [];
    } else if (currentPath === "/duration") {
      surveyData.freemiumDurationMinutes = mapDuration(selections["/duration"]);
    } else if (currentPath === "/numDays") {
      surveyData.freemiumDaysPerWeek = mapDaysPerWeek(selections["/numDays"]);
    } else if (currentPath === "/trainerGender") {
      surveyData.freemiumTrainerGender = mapTrainerGender(
        selections["/trainerGender"],
      );
    } else if (currentPath === "/fitnessGoal") {
      surveyData.freemiumPrimaryGoal = mapFitnessGoal(
        selections["/fitnessGoal"],
      );
    }

    console.log("Mapped survey data:", surveyData);
    return surveyData;
  };

  // Update the survey data for the current path. Only relevant for some pages.
  const updateSurveyDataForPath = (currentPath: string) => {
    if (data?.userId) {
      console.log(
        currentPath,
        ": ",
        data.userId,
        " Updating survey data with selections: ",
        mapSelectionsToSurveyData(currentPath),
      );
      updateSurveyData({
        userId: data.userId,
        data: mapSelectionsToSurveyData(currentPath),
      });
    }
  };

  // Decide which page is next.
  const nextPage = () => {
    const currentPath = window.location.pathname;
    console.log("All selections:", selections);
    setErrorMessage(undefined);

    if (currentPath === "/" || currentPath === "/intro") {
      navigate({ to: "/trainingStyle" });
    } else if (currentPath === "/trainingStyle") {
      updateSurveyDataForPath(currentPath);
      navigate({ to: "/equipment" });
    } else if (currentPath === "/equipment") {
      updateSurveyDataForPath(currentPath);
      navigate({ to: "/duration" });
    } else if (currentPath === "/duration") {
      updateSurveyDataForPath(currentPath);
      navigate({ to: "/numDays" });
    } else if (currentPath === "/numDays") {
      updateSurveyDataForPath(currentPath);
      navigate({ to: "/trainerGender" });
    } else if (currentPath === "/trainerGender") {
      updateSurveyDataForPath(currentPath);
      navigate({ to: "/fitnessGoal" });
    } else if (currentPath === "/fitnessGoal") {
      updateSurveyDataForPath(currentPath);
      navigate({ to: "/trainingPlans" });
    } else if (
      currentPath === "/trainingPlans" ||
      currentPath === "/trainingPlanDetails"
    ) {
      navigate({ to: "/signUp" });
    }
    if (currentPath === "/signUp") {
      try {
        createFreemium.mutate(
          {
            userId: data?.userId || "",
            data: {
              first_name: firstName || "",
              last_name: lastName || "",
              email: email || "",
              phone_number: phoneNumber || "",
              plan_id: selectedPlan?.plan_id || "",
            },
          },
          {
            onError: (error: any) => {
              console.error("Error creating freemium account:", error);
              // Only show specific message for 404 errors
              if (error.status_code === 400) {
                setErrorMessage(error.message);
              } else {
                setErrorMessage("Something went wrong. Please try again.");
              }
            },
          },
        );
      } catch (error) {
        console.error("Error creating freemium account:", error);
        setErrorMessage("Something went wrong. Please try again.");
      }
    }
  };

  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));
  return (
    <Container
      style={{
        // Makes the container a flexbox container.
        display: "flex",
        //  Sets the container's width to 100% of its parent.
        width: "100%",
        // Ensures the container is at least the height of the viewport but can grow if needed.
        height: "100vh",
        // Distributes space between child elements.
        justifyContent: "space-between",
        // Aligns child elements to center of the container's cross axis.
        alignItems: "stretch",
        // Prevents the container from shrinking.
        flexShrink: 0,
        // Stretches the container to fill the parent's cross axis.
        alignSelf: "stretch",
        // Adds a gap between child elements.
        gap: theme.spacing(2),
        // Adds padding inside the container.
        padding: theme.spacing(2),
        // Ensures padding is included in the element's total height and width.
        boxSizing: "border-box",
        // Prevent scrolling.
        overflow: "hidden",
        flexDirection: "row",
      }}
    >
      {/* Container showing the left side of the screen with an image, if the page is viewed on Desktop. */}
      {isDesktop && imageUrl && (
        <Box
          component="img"
          src={imageUrl}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            // Allow the image to take its natural size.
            flex: "0 1 auto",
            maxHeight: "100vh",
            // Set a maximum width for the image container.
            maxWidth: "50%",
            borderRadius: theme.spacing(2),
            objectFit: "cover",
          }}
        />
      )}
      {/**
       * Container showing the right side of the screen. It contains logo, main contents, and a button at
       * at the bottom. The contents depend on the layout route.
       */}
      <Box
        sx={{
          // Makes the container a flexbox container.
          display: "flex",
          // Orients the children in the column.
          flexDirection: "column",
          // Aligns the items in the center.
          alignItems: "center",
          flex: "1 0 0",
          // Ensures the container is at least the height of the viewport but can grow if needed.
          //minHeight: "100%",
          maxHeight: "100vh",
          // Add flexGrow to make the container take up all available space.
          flexGrow: 1,
        }}
      >
        <Box
          component="img"
          src="/assets/trainwell-wordmark.svg"
          alt="logo"
          // Automatically scale height.
          width={120}
          sx={{
            marginLeft: theme.spacing(2),
            marginRight: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
        />
        {showProgressBar && (
          <ProgressBar
            steps={6}
            currentStep={currentStep}
            onChangeStep={() => undefined}
          />
        )}
        <Box
          sx={{
            display: "flex",
            width: "100%",
            // Add flexGrow to make the container take up all available space.
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
          }}
        >
          <Outlet />
        </Box>
        {isButtonVisible && (
          <FilledButton
            disabled={!isButtonEnabled}
            onClick={nextPage}
            sx={{
              marginLeft: theme.spacing(2),
              marginRight: theme.spacing(2),
              width: "100%",
              display: "flex",
              maxWidth: { xs: "420px", md: imageUrl ? "420px" : "720px" },
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {buttonText}
          </FilledButton>
        )}
      </Box>
    </Container>
  );
}

function mapTrainingStyle(
  styles: SelectionButtonProps[] | undefined,
):
  | (
      | "traditional_strength"
      | "functional_strength"
      | "yoga"
      | "hiit"
      | "cardio"
      | "calisthenics"
    )[]
  | undefined {
  if (styles)
    return styles.map(
      (style) =>
        style.id as
          | "traditional_strength"
          | "functional_strength"
          | "yoga"
          | "hiit"
          | "cardio"
          | "calisthenics",
    );
  return undefined;
}

function mapDuration(
  durations: SelectionButtonProps[] | undefined,
): 60 | 30 | undefined {
  console.log("Duration:", durations);
  if (durations) return durations[0].id as unknown as 60 | 30;
  return undefined;
}

function mapDaysPerWeek(
  days: SelectionButtonProps[] | undefined,
): 3 | 5 | undefined {
  console.log("Days:", days);
  if (days) return days[0].id as unknown as 3 | 5;
  return undefined;
}

function mapTrainerGender(
  genders: SelectionButtonProps[] | undefined,
): "male" | "female" | "no_preference" | undefined {
  console.log("Gender", genders);
  if (genders) return genders[0].id as "male" | "female" | "no_preference";
  else return "no_preference";
}

function mapFitnessGoal(
  goals: SelectionButtonProps[] | undefined,
):
  | "gain_muscle"
  | "build_strength"
  | "lose_fat"
  | "improve_health"
  | undefined {
  if (goals)
    return goals[0].id as
      | "gain_muscle"
      | "build_strength"
      | "lose_fat"
      | "improve_health";
  return undefined;
}
