// trainwell color scheme.
export const trainwellColor = {
  // Neutral
  neutral1: "#FFFFFF",
  neutral2: "#F6F6F6",
  neutral3: "#E9E9E9",
  neutral4: "#DEDEDE",
  neutral5: "#A8A8A8",
  neutral6: "#767676",
  neutral7: "#5C5C5C",
  neutral8: "#252525",

  // Primary
  primary1: "#F4F2F8",
  primary2: "#DDD7EA",
  primary3: "#775FAB",
  primary4: "#443564",

  // Secondary
  secondary1: "#EDF6FD",
  secondary2: "#C8E3F9",
  secondary3: "#5496D9",
  secondary4: "#2870B8",

  // Tertiary
  tertiary1: "#FCF9EE",
  tertiary2: "#F6EDCB",
  tertiary3: "#EAD485",
  tertiary4: "#9D7F10",

  // Success
  success1: "#F0FAF4",
  success2: "#C1EBD4",
  success3: "#379764",

  // Error
  error1: "#FDEEED",
  error2: "#F7BDB6",
  error3: "#EC4835",
};
