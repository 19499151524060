import { createTheme, responsiveFontSizes } from "@mui/material";
import "./theme.css";
import { trainwellColor } from "./themePrimitives";

const defaultTheme = createTheme();

export const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: trainwellColor.primary4,
      },
      secondary: {
        main: trainwellColor.secondary3,
      },
      error: {
        main: trainwellColor.error3,
      },
    },
    typography: {
      fontFamily: "'CircularStd', sans-serif",
      h1: {
        fontSize: defaultTheme.typography.pxToRem(96),
        lineHeight: 1.16,
        fontWeight: 900,
        fontStyle: "normal",
        letterSpacing: "-1.5px",
      },
      h2: {
        fontSize: defaultTheme.typography.pxToRem(60),
        lineHeight: 1.2,
        fontWeight: 900,
        fontStyle: "normal",
        letterSpacing: "-0.5px",
      },
      h3: {
        fontSize: defaultTheme.typography.pxToRem(48),
        lineHeight: 1.16,
        fontWeight: 700,
        fontStyle: "normal",
      },
      h4: {
        fontSize: defaultTheme.typography.pxToRem(34),
        lineHeight: 1.23,
        fontWeight: 700,
        fontStyle: "normal",
      },
      h5: {
        fontSize: defaultTheme.typography.pxToRem(24),
        lineHeight: 1.33,
        fontWeight: 700,
        fontStyle: "normal",
      },
      h6: {
        fontSize: defaultTheme.typography.pxToRem(20),
        lineHeight: 1.9,
        fontWeight: 700,
        fontStyle: "normal",
        letterSpacing: "0.15px",
      },
      subtitle1: {
        fontSize: defaultTheme.typography.pxToRem(18),
        lineHeight: 1.44,
        fontWeight: 400,
        fontStyle: "normal",
      },
      subtitle2: {
        fontSize: defaultTheme.typography.pxToRem(16),
        lineHeight: 1.5,
        fontWeight: 700,
        fontStyle: "normal",
      },
      body1: {
        fontSize: defaultTheme.typography.pxToRem(16),
        lineHeight: 1.5,
        fontWeight: 400,
        fontStyle: "normal",
      },
      body2: {
        fontSize: defaultTheme.typography.pxToRem(14),
        lineHeight: 1.47,
        fontWeight: 400,
        fontStyle: "normal",
      },
      overline: {
        fontSize: defaultTheme.typography.pxToRem(12),
        lineHeight: 1.5,
        fontWeight: 500,
        fontStyle: "normal",
        textTransform: "uppercase",
        letterSpacing: "1px",
      },
      caption: {
        fontSize: defaultTheme.typography.pxToRem(13),
        lineHeight: 1.38,
        fontStyle: "normal",
        fontWeight: 500,
      },
      button: {
        fontSize: defaultTheme.typography.pxToRem(18),
        lineHeight: 1.44,
        fontWeight: 700,
        fontStyle: "normal",
        letterSpacing: "0.1px",
      },
    },
    shape: {
      borderRadius: 8,
    },
    components: {
      MuiButton: {
        styleOverrides: {
          root: {
            textTransform: "none", // Keeps the text from being capitalized.
          },
        },
      },
      MuiStack: {
        defaultProps: {
          useFlexGap: true,
        },
      },
    },
  }),
);
